<template>
  <div>
    <h4 class="mb-2">
      Time Limit Settings
    </h4>

    <!-- Teams Table -->
    <b-table
      :fields="fields"
      :items="rows"
      responsive
      show-empty
    >

      <template #cell(name)="row">
        <b-avatar
          :src="getAvatar(row.item.avatar)"
          :text="getInitials(row.item.name)"
          :variant="getRandomVariant()"
          badge
          :badge-variant="row.item.isOnline ? 'success' : 'secondary'"
          class="mr-1"
        />
        {{ row.item.name }}
      </template>

      <template #cell(hours)="row">
        <div v-if="row.item.hours">
          {{ row.item.hours }} hrs /
          <span class="text-lowercase">{{ row.item.limitType.substring(0, row.item.limitType.length - 2) }}</span>
        </div>
        <span v-else>No Limit</span>
      </template>
      <template #cell(actions)="row">
        <b-button
          v-b-tooltip.top="'Change limit Setting'"
          variant="primary"
          size="sm"
          class="py-1 cursor-pointer"
          :disabled="!canEditLimit"
          @click="showModal(row.item)"
        >
          <feather-icon
            icon="Edit2Icon"
          />
        </b-button>

        <b-button
          v-if="row.item.hours"
          v-b-tooltip.top="'Remove limit'"
          variant="danger"
          size="sm"
          class="py-1 mx-1"
          :disabled="!canEditLimit"
          @click="showModal(row.item, 'delete')"
        >
          <feather-icon
            icon="TrashIcon"
          />
        </b-button>
      </template>
    </b-table>

    <!-- Time Limit Setting Modal -->
    <b-modal
      id="time-limit-setting-modal"
      :title="`Edit Time Limit for ${form.name}`"
      ok-title="Submit"
      :busy="isProcessing"
      @ok="updateTimeLimit"
      @hidden="() => form = {}"
    >
      <b-overlay :show="isProcessing">
        <validation-observer ref="timeLimitSettingForm">
          <!-- Time Limit -->
          <validation-provider
            #default="{errors}"
            rules="required"
            name="time limit"
          >
            <b-form-group label="Time Limit (hrs)">
              <b-form-input
                v-model="form.hours"
                type="text"
                placeholder="Time Limit in Hours"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Limit Type -->
          <validation-provider
            #default="{errors}"
            rules="required"
            name="limit for"
          >
            <b-form-group label="Limit For">
              <b-form-select
                v-model="form.type"
                :options="limitForOptions"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </validation-observer>
      </b-overlay>
    </b-modal>

    <!-- Time Limit Delete Modal -->
    <b-modal
      id="time-limit-delete-modal"
      ok-title="Delete"
      ok-variant="danger"
      @ok="deleteTimeLimit"
    >
      Are you sure you want to remove time limit for {{ form.name }}?
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BFormSelect, BTable, BButton, BModal, BOverlay, BAvatar,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    BTable,
    BButton,
    BModal,
    BOverlay,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    selectedUser: {
      type: String,
      required: true,
    },
    canEditLimit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      limitForOptions: [
        {
          value: 'WEEKLY',
          text: 'Weekly',
        },
        {
          value: 'MONTHLY',
          text: 'Monthly',
        },
      ],
      searchFor: '',
      selectedProject: this.$store.state.project.selectedProject,
      fields: [
        { label: 'User', key: 'name', thClass: 'text-capitalize' },
        { label: 'Time Limit (hrs)', key: 'hours', thClass: 'text-capitalize' },
        {
          label: 'Actions', key: 'actions', thClass: 'text-capitalize', tdClass: 'w-25',
        },
      ],
      form: {},
      selectedIndex: null,
      isProcessing: false,
      rows: [],
    }
  },
  watch: {
    selectedUser: {
      handler(val) {
        if (val) this.getUserTimeLimit()
      },
      immediate: true,
    },
  },
  mounted() {
    this.getUserTimeLimit()
  },
  methods: {
    updateTimeLimit(bvModal) {
      bvModal.preventDefault()
      this.$refs.timeLimitSettingForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.team.updateTeamMemberLimit({
            userUid: this.form.uid,
            projectUid: this.selectedProject,
            hours: parseInt(this.form.hours, 10) ?? 0,
            type: this.form.type ?? 'WEEKLY',
          }).then(res => {
            this.getUserTimeLimit()
            this.showSuccess(res.data.updateUserProjectTimeLimit.message)
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
            this.$bvModal.hide('time-limit-setting-modal')
          })
        }
      })
    },
    deleteTimeLimit() {
      this.isProcessing = true
      useApollo.team.updateTeamMemberLimit({
        userUid: this.form.uid,
        projectUid: this.selectedProject,
        hours: 0,
        type: this.form.type ?? 'WEEKLY',
      }).then(res => {
        this.rows[0].hours = 0
        this.showSuccess(res.data.updateUserProjectTimeLimit.message)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showModal(user, action) {
      this.form = {
        uid: user.uuid,
        name: user.name,
        hours: user.hours,
        type: user.limitType ?? 'WEEKLY',
      }
      this.$nextTick(() => {
        if (action === 'delete') this.$bvModal.show('time-limit-delete-modal')
        else this.$bvModal.show('time-limit-setting-modal')
      })
    },
    getUserTimeLimit() {
      useApollo.project.getUserTimeSettings({
        userUid: this.selectedUser,
        projectUid: this.selectedProject,
      }).then(response => {
        const { user } = response.data
        this.rows = [
          {
            uuid: user.uuid,
            name: user.name,
            isOnline: user.isOnline,
            avatar: user.avatar,
            hours: user?.projectSettings?.data[0]?.information?.hours,
            limitType: user?.projectSettings?.data[0]?.information?.type,
          },
        ]
      })
    },
  },
}
</script>

<template>
  <div>
    <h4 class="mb-2">
      General Settings
    </h4>
    <b-overlay :show="isProcessing">
      <b-row
        v-for="setting, index in settings"
        :key="index"
        class="mb-4"
      >
        <b-col md="10">
          <div class="d-flex align-items-center">
            <feather-icon
              :icon="setting.icon"
              size="72"
            />
            <div class="ml-1">
              <h6>{{ setting.title }}</h6>
              <p>
                {{ setting.description }}
              </p>
            </div>
          </div></b-col>

        <b-col
          md="2"
          class="text-right"
        >
          <div
            v-if="setting.inputType === 'switch'"
            class="checkbox-wrapper"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="form[setting.name]"
                class="colorful-switch my-1"
                button-variant="success"
                :value="false"
                :unchecked-value="true"
                switch
                pill
              >
                <span class="switch-icon-left">
                  <feather-icon
                    icon="CheckIcon"
                    color="#ffffff"
                  />
                </span>
                <span class="switch-icon-right">
                  <feather-icon
                    icon="XIcon"
                    color="#ffffff"
                  />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </div>

          <b-input-group
            v-else
            size="md"
            :append="setting.append ? setting.append : ''"
          >
            <b-form-input
              v-model="form[setting.name]"
              :type="setting.inputType"
              :min="0"
              class="my-1 text-right"
            />
          </b-input-group>

        </b-col>
      </b-row>
    </b-overlay>
    <hr>
    <b-button
      variant="primary"
      class="float-right"
      :disabled="isProcessing"
      @click="updateUserGeneralSettings"
    >
      Save
    </b-button>
  </div>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BRow, BCol, BFormCheckbox, BButton, BOverlay, BFormGroup, BFormInput, BInputGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInput,
    BInputGroup,
  },
  props: {
    selectedUser: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        disableScreencast: false,
        idleTime: 15,
      },
      settings: {
        disableScreencast: {
          icon: 'MonitorIcon',
          name: 'disableScreencast',
          title: 'Enable Screen Capture',
          description: 'Capture screenshots periodically and send them to server.',
          inputType: 'switch',
        },
        idleTime: {
          icon: 'ClockIcon',
          name: 'idleTime',
          title: 'Idle Time Setting',
          description: 'Stop tracking time after being idle for',
          inputType: 'number',
          append: 'minutes',
        },
      },
      isProcessing: false,
    }
  },
  mounted() {
    this.getUserGeneralSettings()
  },
  methods: {
    getUserGeneralSettings() {
      this.isProcessing = true
      useApollo.users.getUserGeneralSettings({
        userUid: this.selectedUser,
        projectUid: this.$store.state.project.selectedProject,
      }).then(response => {
        const data = response.data.project.users.data[0]?.generalSettings?.data[0]?.information
        this.form = {
          disableScreencast: !!(data && data.disableScreencast),
          idleTime: data && data.idleTime ? data.idleTime : 15,
        }
      }).finally(() => { this.isProcessing = false })
    },
    updateUserGeneralSettings() {
      useApollo.users.updateUserGeneralSettings({
        userUid: this.selectedUser,
        projectUid: this.$store.state.project.selectedProject,
        input: {
          ...this.form,
          idleTime: Number(this.form.idleTime),
        },
      }).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.updateUserProjectGeneralSettings.message,
          },
        })
      })
    },
  },
}
</script>

<style scoped>
.custom-switch {
  scale: 1.5;
}
.input-group-text {
  height: 38px;
  margin-top: 14px;
}
.checkbox-wrapper {
  padding-left: 90px;
}
</style>

<style lang="scss">
  @import "@core/scss/base/pages/ui-colors.scss"
</style>

<template>
  <div>
    <h4 class="mb-2">
      User Status
    </h4>

    <b-overlay :show="isProcessing">
      <b-row
        v-for="setting, index in settings"
        :key="index"
        class="mb-4"
      >
        <b-col md="10">
          <div class="d-flex align-items-center">
            <feather-icon
              :icon="setting.icon"
              size="72"
            />
            <div class="ml-1">
              <h6>{{ setting.title }}</h6>
              <p>
                {{ setting.description }}
              </p>
            </div>
          </div></b-col>

        <b-col
          md="2"
          class="text-right"
        >
          <div
            class="checkbox-wrapper"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="form[setting.name]"
                class="colorful-switch my-1"
                button-variant="success"
                :value="true"
                :unchecked-value="false"
                switch
                pill
              >
                <span class="switch-icon-left">
                  <feather-icon
                    icon="CheckIcon"
                    color="#ffffff"
                  />
                </span>
                <span class="switch-icon-right">
                  <feather-icon
                    icon="XIcon"
                    color="#ffffff"
                  />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
    <hr>
    <b-button
      variant="primary"
      class="float-right"
      :disabled="isProcessing"
      @click="changeUserStatusInProject"
    >
      Save
    </b-button>
  </div>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BRow, BCol, BFormCheckbox, BButton, BOverlay, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BOverlay,
    BFormGroup,
  },
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        enableUser: true,
      },
      settings: {
        disableUserStatus: {
          icon: 'UserCheckIcon',
          name: 'enableUser',
          title: 'Activate User Status',
          description: `Activate ${this.selectedUser.name}'s status in project - ${this.$store.state.project.project.name}`,
          inputType: 'switch',
        },
      },
      isProcessing: false,
    }
  },
  mounted() {
    if (this.selectedUser) {
      this.form.enableUser = this.selectedUser.projectRoles.data[0].deactivatedAt === null
    }
  },
  methods: {
    changeUserStatusInProject() {
      this.isProcessing = true
      useApollo.users.changeUserStatusInProject({
        userUid: this.selectedUser.uuid,
        projectUid: this.$store.state.project.selectedProject,
        enable: this.form.enableUser,
      }).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.changeUserStatusInProject.message,
          },
        })
        this.$emit('refetch-users')
      }).finally(() => { this.isProcessing = false })
    },
  },
}
</script>

<style scoped>
.custom-switch {
  scale: 1.5;
}
.input-group-text {
  height: 38px;
  margin-top: 14px;
}
.checkbox-wrapper {
  padding-left: 90px;
}
</style>

<style lang="scss">
  @import "@core/scss/base/pages/ui-colors.scss";
</style>

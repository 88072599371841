<template>
  <b-modal
    :visible="!!userInfo"
    size="lg"
    hide-footer
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <div v-if="userInfo">
      <profile
        :key="userInfo.uuid"
        :user-info="userInfo"
        :from-modal="true"
      />
    </div>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import Profile from '@/views/common/Profile/index.vue'

export default {
  components: {
    BModal,
    Profile,
  },
  props: {
    userInfo: {
      type: [String, Object],
      default: () => null,
    },
  },
}
</script>
